import { Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Stack, Typography } from '@mui/material';
import type { Event, EventControl } from 'core';
import type { FC } from 'react';
import { ControlCardList } from '../../components/event-control/control-card-list.js';
import { EditControlCardView } from '../../components/event-control/edit-control-card-view.js';
import { PageTitle } from '../../components/page-title/page-title.js';

export interface Props {
  busy: boolean;
  canSave: boolean;
  controls: EventControl[];
  editControl?: EventControl;
  event: Event.WithCourses;
  eventType: string;
  newControl?: EventControl;
  onAddControl: () => void;
  onChangeControl: (from: EventControl, to: EventControl) => void;
  onNewControlCancel: () => void;
  onNewControlDone: (control: EventControl) => void;
  onRemoveControl: (control: EventControl) => void;
  onSaveClick: () => void;
  setEditControl: (control: EventControl | undefined) => void;
}

export const EventControlsView: FC<Props> = ({
  busy,
  canSave,
  event,
  controls,
  editControl,
  eventType,
  newControl,
  onAddControl,
  onChangeControl,
  onNewControlCancel,
  onNewControlDone,
  onRemoveControl,
  onSaveClick,
  setEditControl,
}) => (
  <PageTitle title={`${event.title} Controls`}>
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Stack spacing={3}>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Typography variant="h4">{event.title} Controls</Typography>
          <LoadingButton
            disabled={!canSave}
            variant="contained"
            startIcon={<SaveIcon />}
            loading={busy}
            onClick={() => onSaveClick()}
          >
            Save
          </LoadingButton>
        </Box>
        {controls.length ? (
          <ControlCardList
            controls={controls}
            editControl={editControl}
            eventType={eventType}
            onChangeControl={onChangeControl}
            onRemoveControl={onRemoveControl}
            onStartEdit={setEditControl}
            overlayImage={
              event.overlayImage
                ? {
                    url: `/api/v1/event/${event._id}/${event.overlayImage.path}`,
                    corners: event.overlayImage.corners,
                  }
                : undefined
            }
          />
        ) : (
          <Typography variant="body2" color="text.secondary" fontStyle="italic">
            Define all the controls that will be used in the courses of this
            event.
          </Typography>
        )}
        {newControl ? (
          <EditControlCardView
            control={newControl}
            eventType={eventType}
            onCancel={onNewControlCancel}
            onDone={onNewControlDone}
          />
        ) : (
          <LoadingButton
            variant="outlined"
            loading={busy}
            onClick={() => onAddControl()}
          >
            Add Control
          </LoadingButton>
        )}
      </Stack>
    </Container>
  </PageTitle>
);
