import type { Event } from 'core';
import type { ComponentType, FC } from 'react';
import { useParams } from 'react-router-dom';
import uri from 'uri-tag';
import { MiddleSpinner } from '../../components/spinner/spinner.js';
import { useAxios } from '../../hooks/use-axios.js';
import { ErrorPage } from '../error/page.js';
import { NotFoundPage } from '../not-found/page.js';

export interface Props {
  event: Event.WithCourses;
  refresh: () => void;
}

export const withEvent =
  (Component: ComponentType<Props>): FC =>
  () => {
    const { id } = useParams();

    const [event, refresh] = useAxios<Event.WithCourses>(
      id && uri`/api/v1/event/${id}`,
    );

    if (event.loading) return <MiddleSpinner />;
    if (event.error) return <ErrorPage error={event.error} />;
    if (!event.data) return <NotFoundPage />;

    return <Component event={event.data} refresh={refresh} />;
  };
