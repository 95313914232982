import { captureException } from '@sentry/react';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Control, EventControl } from 'core';
import { fromEventControl, sortEventControl, toEventControl } from 'core';
import omit from 'lodash.omit';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import uri from 'uri-tag';
import { MiddleSpinner } from '../../components/spinner/spinner.js';
import { EventControlsView } from './view.js';
import type { Props } from './with-event.js';
import { withEvent } from './with-event.js';

export const EventControls: FC<Props> = ({ event, refresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [controls, setControls] = useState<Record<string, Control>>(
    event.controls ?? {},
  );
  const [newControl, setNewControl] = useState<EventControl>();
  const [editControl, setEditControl] = useState<EventControl>();
  const [editingControls, setEditingControls] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    setEditingControls(!!newControl || !!editControl);
  }, [newControl, editControl]);

  const controlArray = useMemo(
    () =>
      Object.entries(controls ?? {})
        .map(([_id, control]) => toEventControl(_id, control))
        .sort(sortEventControl),
    [controls],
  );

  if (!location) return MiddleSpinner({});

  const onAddControl = (): void =>
    setNewControl({
      _id: '',
      location: event.location,
      type: 'control',
    });

  const onNewControlCancel = (): void => setNewControl(undefined);

  const onNewControlDone = (control: EventControl): void => {
    setNewControl(undefined);
    setControls({ ...controls, [control._id]: fromEventControl(control) });
  };

  const onChangeControl = (from: EventControl, to: EventControl): void => {
    setEditControl(undefined);
    setControls({
      ...omit(controls, from._id),
      [to._id]: fromEventControl(to),
    });
  };

  const onRemoveControl = (control: EventControl): void => {
    setControls(omit(controls, control._id));
  };

  const onSaveClick = (): void => {
    setBusy(true);
    axios
      .patch(uri`/api/v1/event/${event._id}`, { controls })
      .then(() => {
        enqueueSnackbar('Event updated', { variant: 'success' });
        setBusy(false);
        refresh();
        return;
      })
      .catch((err: AxiosError) => {
        captureException(err);
        setBusy(false);
        enqueueSnackbar(`Unable to update: ${err.message}`, {
          variant: 'error',
        });
        refresh();
      });
  };

  return EventControlsView({
    busy,
    canSave: !editingControls,
    controls: controlArray,
    editControl,
    event,
    eventType: event.type ?? 'long',
    newControl,
    onAddControl,
    onChangeControl,
    onNewControlCancel,
    onNewControlDone,
    onRemoveControl,
    onSaveClick,
    setEditControl,
  });
};

export default withEvent(EventControls);
